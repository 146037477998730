// 会务模版
import { get, put, post, del } from "./base";
export function getTemplateList(pageNo,pageSize){
    return get(`/meet/task?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export function getTemplateListOpen(pageNo,pageSize,isOpen){
      return get(`/meet/task?pageNo=${pageNo}&pageSize=${pageSize}&isOpen=${isOpen}`)
  }
export function addTemplate(data){
     return post('/meet/task',data)
}
export function editTemplate(data){
    return put('/meet/task',data)
}
export function delTemplate(code){
     return del(`/meet/task/${code}`)
}

// 会务事务、
export function getMeetcategory(taskTemplateCode,parentId){
      return get(`/meet/task/category?taskTemplateCode=${taskTemplateCode}&parentId=${parentId}`)
}
// 拷贝模板
export function copyTemplate(data){
    return post('/meet/task/copy',data)

}
// 添加类别
export function addMeetcategory(data){
      return post('/meet/task/category',data)
}
// 删除
export function editMeetcategory(data){
      return put('/meet/task/category',data)
}
// 移动
export function moveMeetcategory(type,id){
      return put(`/meet/task/category/move/${type}/${id}`)
}
// 删除
export function delMeetcategory(id){
      return del(`/meet/task/category/${id}`)
}
// 类别
export function getMeetcategoryType(){
     return get('/meet/task/type')
}
<template>
  <div>
    <div class="header">
      <span class="header_left">
        <a-icon
          @click="goBack"
          type="left-circle"
          style="
            font-size: 19px;
            margin-right: 7px;
            line-height: 50px;
            margin-top: 3px;
          "
        />
        系统模版
      </span>
      <div class="header_right" @click="copy" style="background-color: #1890FF;color: white;border-radius: 5px">复制模版修改</div>
    </div>
    <div class="body">
      <div class="body_left">
        <div class="body_left_top">会务事务</div>
        <div>
          <a-tree
            :replace-fields="replaceFields"
            :tree-data="list"
            @select="isSelect"
          />
        </div>
      </div>
      <div class="body_between">
        <div class="body_left_top">任务分类</div>
        <div>
          <a-table
            :customRow="rowClick"
            :columns="invoice"
            class="table"
            :data-source="invoiceData"
            :pagination="false"
            :rowClassName="setRowClassName"
          >
            <span slot="name_hhh" slot-scope="text, record">
              {{ text }}
            </span>
          </a-table>
        </div>
      </div>
      <div class="body_right">
        <div class="body_left_top">任务明细</div>
        <div>
          <a-table
            :columns="invoiceRight"
            class="table"
            :data-source="invoiceRightData"
            :pagination="false"
          >
          </a-table>
        </div>
      </div>
    </div>
    <!--新增对话框 -->
    <a-modal
      @cancel="cancel"
      title="复制模板"
      :visible="visible"
      width="460px"
      @ok="handleOk"
    >
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="模板名称" prop="name">
          <a-input v-model="form.name" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="模板描述" prop="detail">
          <a-textarea v-model="form.detail" placeholder="请输入" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { getMeetcategory, copyTemplate } from "@/service/meetingTemplat_api.js";
export default {
  data() {
    return {
      list: [],
      invoice: [
        {
          title: "名称",
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name_hhh" },
        },
        {
          title: "类型",
          dataIndex: "taskTypeName",
          key: "taskTypeName",
        },

      ],
      invoiceRight: [
        {
          title: "名称",
          dataIndex: "name",
          key: "name",
        },

      ],
      replaceFields: { title: "name", key: "id" },
      invoiceData: [],
      invoiceRightData: [],
      visible: false,
      form: {},
      labelCol: { span: 5 },
      wrapperCol: { span: 17 },
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      parentIdRight: "",
    };
  },
  mounted() {
    this.$store.dispatch("isShowNav", false); //恢复导航栏
    this.load();
  },
  methods: {
    setRowClassName(record, index) {
      //判断并返回行的样式名称
      if (record.id == this.parentIdRight) {
        return "bg-blue";
      }
    },
    //  onTreeNodeClick(selectedKeys, e) {
    //   // 禁用树节点的取消选中功能
    //   e.node.props.disabled = true;
    // },
    // 一级
    async load() {
      const res = await getMeetcategory(this.$route.query.code, 0);
      if (res.code == 0) {
        res.data.forEach((item) => {
          item.children = [];
          // item.selectable = false;
        });
        this.list = res.data;
        this.loadTwo();
      }
    },
    loadTwo() {
      this.list.forEach((item) => {
        getMeetcategory(this.$route.query.code, item.id).then((res) => {
          if (res.code == 0) {
            item.children = res.data;
          }
        });
      });
    },
    async copy() {
      if (this.$route.query.member >= 5) {
        this.$message.warn("最多支持自定义5个模板！");
        return;
      }
      this.visible = true;
      //        let data={

      // "code":"zs8ih4zc",//要拷贝的系统模板code值
      // "name":"拷贝模板测试2",//自定义模板名称
      // "detail":"拷贝2"//自定义模板，备注
    },

    async isSelect(selectedKeys, { node }) {
      node.onExpand();
      let flag = false;
      this.list.forEach((item) => {
        if (item.id == selectedKeys[0]) {
          flag = true;

          return;
        }
      });
      if (!flag) {
        this.invoiceData = [];
        this.invoiceRightData = [];
        const res = await getMeetcategory(
          this.$route.query.code,
          selectedKeys[0]
        );
        if (res.code == 0) {
          this.invoiceData = res.data;
        }
      }
    },

    rowClick: function (record, index) {
      return {
        on: {
          click: () => {
            console.log(record);
            getMeetcategory(this.$route.query.code, record.id).then((res) => {
              if (res.code == 0) {
                this.invoiceRightData = res.data;
                this.parentIdRight = record.id;
              }
            });
          },
        },
      };
    },
    cancel() {
      (this.visible = false), (this.form = {});
    },
    async handleOk() {
      let data = {
        code: this.$route.query.code, //要拷贝的系统模板code值
        name: this.form.name,
        detail: this.form.detail,
      };
      const res = await copyTemplate(data);
      if (res.code == 0) {
         this.$message.success("复制成功！");
         this.visible = false;
          await this.$router.push({
          name: "setupTemplate",
          query: {
            code: res.data.data.code,
            name: res.data.data.name,
          },
        });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 50px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 0 30px;
  font-size: 16px;
  // line-height: 50px;
  color: black;
  border-top: 1px solid #edeff2;
  border-bottom: 1px solid #edeff2;
  // font-weight: 600;
  // color: $topTitleColor;
  .header_left {
    display: flex;
    align-items: center;
  }
  .header_right {
    font-size: 14px;
    color: rgb(37, 36, 36);
    border: 1px solid #d3d7da;
    display: flex;
    align-items: center;
    height: 30px;
    margin: auto 0;
    padding: 15px 20px;
    cursor: pointer;
  }
}
.body {
  width: 100%;
  min-height: 810px;
  //  height: 100vh;
  background: #edeff2;
  border: 10px solid #edeff2;
  //  white-space:nowrap;
  //  display:flex;
  // justify-content: space-around;
}
.body_left {
  // flex-shrink: 0;
  width: 15%;
  min-height: 450px;
  background: white;
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
}
.body_between {
  width: 40%;
  min-height: 450px;
  background: white;
  margin-right: 20px;
  display: inline-block;
  vertical-align: top;
}
.body_right {
  display: inline-block;
  width: 40%;
  min-height: 450px;
  background: white;
  vertical-align: top;
}
.body_left_top {
  height: 50px;
  width: 100%;
  line-height: 50px;
  padding: 0px 15px 0px 10px;
  border-bottom: 1px solid #edeff2;
  font-size: 14px;
  color: rgb(37, 36, 36);
  display: flex;
  justify-content: space-between;
}
// ::v-deep .ant-tree-treenode-switcher-close{
//      width:2em  !important;
//      height:2em !important;

// }
.table {
  width: 94%;
  margin: 10px auto;
}
::v-deep .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: rgb(24, 144, 255);
  background: white;
}
::v-deep .bg-blue {
  background-color: #e6f7ff;
}
</style>
